var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"activeModal":_vm.activeModal},on:{"closeModal":_vm.closeModal}},[_c('v-card',[_c('v-toolbar',{staticClass:"modal-cadastro-toolbar",attrs:{"flat":"","height":"40px"}},[_vm._v(" Transmissões "),_c('v-spacer'),_c('v-icon',{staticClass:"modal-cadastro-close",on:{"click":_vm.closeModal}},[_vm._v("close")])],1),_c('v-container',{staticClass:"pb-0",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-3 pt-0 pb-1 pb-0"},[_c('strong',[_vm._v("Data:")]),_vm._v(" "+_vm._s(_vm._f("datas")(_vm.data,'DD/MM/YYYY'))+" ")]),_c('v-col',{staticClass:"text-right pa-3 pt-0 pb-1 pb-0"},[_c('strong',[_vm._v("Revendedor:")]),_vm._v(" "+_vm._s(_vm.revendedor)+" ")])],1)],1)],1)],1),(_vm.loadingModal)?_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"300px","width":"100%"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('div',{staticClass:"box-table"},[_c('div',{staticClass:"abas",attrs:{"width":"100%"}},[_c('v-tabs',{attrs:{"background-color":"#FFF","height":"30px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"abas-item",attrs:{"href":"#tab-1"}},[_vm._v(" Jogos ")]),_c('v-tab',{staticClass:"abas-item",attrs:{"href":"#tab-2"}},[_vm._v(" Venda de Créditos ")])],1)],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:1,attrs:{"value":'tab-1'}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listaItens,"items-per-page":5,"width":"300px","flat":"","dense":"","mobile-breakpoint":300,"show-expand":""},on:{"click:row":_vm.opemDadosJogos},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-remove",attrs:{"fab":"","min-width":26,"width":26,"color":_vm.variables.colorError,"dark":!_vm.isDeleted(item),"loading":_vm.loading,"disabled":_vm.isDeleted(item)},on:{"click":function($event){$event.stopPropagation();return _vm.cancelarBilhete(item)}}},[_c('v-icon',{attrs:{"size":14}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"item.gameNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isDeleted(item) }},[_vm._v(_vm._s(item.gameNumber))])]}},{key:"item.sweepstakes.cod",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isDeleted(item) }},[_vm._v(_vm._s(item.sweepstakes.cod))])]}},{key:"item.codId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isDeleted(item) }},[_vm._v(_vm._s(item.codId))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isDeleted(item) }},[_vm._v(_vm._s(_vm._f("currency")(item.value)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pr-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Data da Compra ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Data do Cancelamento ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("datas")(item.created,'DD/MM/YYYY HH:mm:ss')))]),(_vm.isDeleted(item))?_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("datas")(item.deleted,'DD/MM/YYYY HH:mm:ss')))]):_c('td',{staticClass:"text-center"},[_vm._v("-")])])])]},proxy:true}],null,true)})],1)]}}])},[_c('template',{slot:"body.append"},[_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headers.length}},[_vm._v("Total de jogos: "+_vm._s(_vm.listaItens.length))])]),_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headers.length - 2}},[_vm._v("Não deletados: "+_vm._s(_vm.listTransmissionsNotDeleted.length))]),_c('th',{staticClass:"text-end",attrs:{"colspan":2}},[_vm._v("Valor: "+_vm._s(_vm._f("currency")(_vm.totalValoresTransmissoes)))])]),_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headers.length - 2}},[_vm._v("Deletados: "+_vm._s(_vm.listTransmissionsDeleted.length))]),_c('th',{staticClass:"text-end",attrs:{"colspan":2}},[_vm._v("Valor: "+_vm._s(_vm._f("currency")(_vm.totalValoresTransmissoesDeleted)))])])])],2)],1),_c('v-tab-item',{key:2,attrs:{"value":'tab-2'}},[_c('v-data-table',{attrs:{"headers":_vm.headersListCreditosVendidos,"items":_vm.listCreditosVendidos,"items-per-page":5,"width":"300px","flat":"","dense":"","mobile-breakpoint":300},on:{"click:row":_vm.opemDadosJogos},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-remove",attrs:{"fab":"","min-width":26,"width":26,"color":_vm.variables.colorError,"dark":!_vm.isCancelado(item),"loading":_vm.loading,"disabled":_vm.isCancelado(item)},on:{"click":function($event){$event.stopPropagation();return _vm.handleCancelarCredito(item)}}},[_c('v-icon',{attrs:{"size":14}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"item.cod",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isCancelado(item) }},[_vm._v(_vm._s(item.cod))])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isCancelado(item) }},[_vm._v(_vm._s(item.updated))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isCancelado(item) }},[_vm._v(_vm._s(item.created))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ cancelado: _vm.isCancelado(item) }},[_vm._v(_vm._s(_vm._f("currency")(item.value)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pr-0",attrs:{"colspan":headers.length}})]}}])},[_c('template',{slot:"body.append"},[_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headersListCreditosVendidos.length}},[_vm._v("Total de jogos: "+_vm._s(_vm.listCreditosVendidos.length))])]),_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headersListCreditosVendidos.length - 1}},[_vm._v("Total de creditos não cancelados: "+_vm._s(_vm.listCreditsNotCanceled.length))]),_c('th',{staticClass:"text-end"},[_vm._v("Valor: "+_vm._s(_vm._f("currency")(_vm.totalValoresVendasCreditos)))])]),_c('tr',{},[_c('th',{attrs:{"colspan":_vm.headersListCreditosVendidos.length - 1}},[_vm._v("Total de creditos cancelados: "+_vm._s(_vm.listCreditsCanceled.length))]),_c('th',{staticClass:"text-end"},[_vm._v("Valor: "+_vm._s(_vm._f("currency")(_vm.totalValoresCreditsCanceled)))])])])],2)],1)],1)],1)])],1),_c('ModalJogos')],1)}
var staticRenderFns = []

export { render, staticRenderFns }