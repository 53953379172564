<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          Transmissões
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container fluid class="pb-0">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="pa-3 pt-0 pb-1 pb-0">
                  <!-- Periodo: {{ rangeDate.dtInicial | data('DD/MM/YYYY') }} a {{ rangeDate.dtFinal | data }} -->
                  <strong>Data:</strong> {{ data | datas('DD/MM/YYYY') }}
                </v-col>

                <v-col class="text-right pa-3 pt-0 pb-1 pb-0">
                  <strong>Revendedor:</strong> {{ revendedor }} 
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col class="pa-3 pt-0 pb-1">
                  <date />
                </v-col>
              </v-row> -->
            </v-card-text>
          </v-card>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>

      <v-container fluid class="pt-0"  v-else>
          <div class="box-table">
            <div class="abas" width="100%">
              <v-tabs
                v-model="tab"
                background-color="#FFF"
                height="30px">
                <v-tab href="#tab-1" class="abas-item">
                  Jogos
                </v-tab>

                <v-tab href="#tab-2" class="abas-item">
                  Venda de Créditos
                </v-tab>
              </v-tabs>
            </div>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item
                :key="1"
                :value="'tab-1'">
                <v-data-table
                  :headers="headers"
                  :items="listaItens"
                  :items-per-page="5"
                  width="300px"
                  flat
                  dense
                  @click:row="opemDadosJogos"
                  :mobile-breakpoint="300"
                  show-expand
                > 
                  <!-- <template
                    v-slot:body="{ items }"
                  >

                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.gameNumber"
                        :class="{ cancelado: isDeleted(item) }"
                        class="box-table-row"
                        @click="opemDadosJogos(item)"
                      >
                        <td><v-btn
                          fab
                          :min-width="26"
                          :width="26"
                          :color="variables.colorError"
                          dark
                          class="btn-remove"
                        >
                          <v-icon :size="14">mdi-trash-can-outline</v-icon></v-btn></td>
                        <td>{{ item.gameNumber }}</td>
                        <td>{{ item.sweepstakes.cod }}</td>
                        <td>{{ item.codId }}</td>
                        <td class="text-end">{{ item.value | currency }}</td>
                        <td class="text-end">{{ item.value | currency }}</td>
                      </tr>

                      
                    </tbody>
                  </template> -->

                  <template v-slot:item.action="{ item }">
                    <v-btn
                      fab
                      :min-width="26"
                      :width="26"
                      :color="variables.colorError"
                      :dark="!isDeleted(item)"
                      class="btn-remove"
                      @click.stop="cancelarBilhete(item)"
                      :loading="loading"
                      :disabled="isDeleted(item)"
                    >
                      <v-icon :size="14">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.gameNumber="{ item }">
                    <span :class="{ cancelado: isDeleted(item) }">{{ item.gameNumber }}</span>
                  </template>

                  <template v-slot:item.sweepstakes.cod="{item}">
                    <span :class="{ cancelado: isDeleted(item) }">{{ item.sweepstakes.cod  }}</span>
                  </template>

                  <template v-slot:item.codId="{ item }">
                    <span :class="{ cancelado: isDeleted(item) }">{{ item.codId }}</span>
                  </template>

                  <template v-slot:item.value="{item}">
                    <span :class="{ cancelado: isDeleted(item) }">{{ item.value | currency  }}</span>
                  </template>

                  <template slot="body.append">
                    <tr class="">
                      <th :colspan="headers.length">Total de jogos: {{ listaItens.length }}</th>
                    </tr>

                    <tr class="">
                      <th :colspan="headers.length - 2">Não deletados: {{ listTransmissionsNotDeleted.length }}</th>
                      <th :colspan="2" class="text-end">Valor: {{ totalValoresTransmissoes | currency}}</th>
                    </tr>
                    <tr class="">
                      <th :colspan="headers.length - 2">Deletados: {{ listTransmissionsDeleted.length }}</th>
                      <th :colspan="2" class="text-end">Valor: {{ totalValoresTransmissoesDeleted | currency}}</th>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pr-0">
                      <!-- <v-data-table 
                        class="fb-table-elem pl-0 pr-0"
                        :headers="dessertHeaders"
                        :items="item"
                        hide-default-header
                        hide-default-footer
                      >
                        <template v-slot:item.created="{item}">
                          {{ item.created | datas }}
                        </template>
                        <template v-slot:item.deleted="{item}">
                          {{ item.deleted | datas }}
                        </template>
                      </v-data-table> -->

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Data da Compra
                              </th>
                              <th class="text-left">
                                Data do Cancelamento
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                              <td class="text-center" v-if="isDeleted(item)">{{ item.deleted | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                              <td class="text-center" v-else>-</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item
                :key="2"
                :value="'tab-2'">
                <v-data-table
                  :headers="headersListCreditosVendidos"
                  :items="listCreditosVendidos"
                  :items-per-page="5"
                  width="300px"
                  flat
                  dense
                  @click:row="opemDadosJogos"
                  :mobile-breakpoint="300"
                > 

                  <!-- <template
                    v-slot:body="{ items }"
                  >

                    <tbody>
                      

                      <tr
                        v-for="item in items"
                        :key="item.cod"
                        :class="{ cancelado: isCancelado(item) }"
                        class="box-table-row"
                      >
                        <td>{{ item.cod }}</td>
                        <td class="text-center" v-if="isCancelado(item)">{{ item.updated | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="text-center" v-else>-</td>
                        <td class="text-center">{{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td class="text-end">{{ item.value | currency }}</td>
                      </tr>

                      <tr class="">
                        <th :colspan="headersListCreditosVendidos.length">Total de jogos: {{ listCreditosVendidos.length }}</th>
                      </tr>

                      <tr class="">
                        <th :colspan="headersListCreditosVendidos.length - 1">Total de creditos não cancelados: {{ listCreditsNotCanceled.length }}</th>

                        <th class="text-end">Valor: {{ totalValoresVendasCreditos | currency}}</th>
                      </tr>
                      <tr class="">
                        <th :colspan="headersListCreditosVendidos.length - 1">Total de creditos cancelados: {{ listCreditsCanceled.length }}</th>
                        <th class="text-end">Valor: {{ totalValoresCreditsCanceled | currency}}</th>
                      </tr>
                    </tbody>
                  </template> -->

                  <template v-slot:item.action="{ item }">
                    <v-btn
                      fab
                      :min-width="26"
                      :width="26"
                      :color="variables.colorError"
                      :dark="!isCancelado(item)"
                      class="btn-remove"
                      @click.stop="handleCancelarCredito(item)"
                      :loading="loading"
                      :disabled="isCancelado(item)"
                    >
                      <v-icon :size="14">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.cod="{ item }">
                    <span :class="{ cancelado: isCancelado(item) }">{{ item.cod }}</span>
                  </template>

                  <template v-slot:item.updated="{item}">
                    <span :class="{ cancelado: isCancelado(item) }">{{ item.updated  }}</span>
                  </template>

                  <template v-slot:item.created="{ item }">
                    <span :class="{ cancelado: isCancelado(item) }">{{ item.created }}</span>
                  </template>

                  <template v-slot:item.value="{item}">
                    <span :class="{ cancelado: isCancelado(item) }">{{ item.value | currency  }}</span>
                  </template>

                  <template slot="body.append">
                    <tr class="">
                      <th :colspan="headersListCreditosVendidos.length">Total de jogos: {{ listCreditosVendidos.length }}</th>
                    </tr>

                    <tr class="">
                      <th :colspan="headersListCreditosVendidos.length - 1">Total de creditos não cancelados: {{ listCreditsNotCanceled.length }}</th>

                      <th class="text-end">Valor: {{ totalValoresVendasCreditos | currency}}</th>
                    </tr>
                    <tr class="">
                      <th :colspan="headersListCreditosVendidos.length - 1">Total de creditos cancelados: {{ listCreditsCanceled.length }}</th>
                      <th class="text-end">Valor: {{ totalValoresCreditsCanceled | currency}}</th>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pr-0">
                      <!-- <v-data-table 
                        class="fb-table-elem pl-0 pr-0"
                        :headers="dessertHeaders"
                        :items="item"
                        hide-default-header
                        hide-default-footer
                      >
                        <template v-slot:item.created="{item}">
                          {{ item.created | datas }}
                        </template>
                        <template v-slot:item.deleted="{item}">
                          {{ item.deleted | datas }}
                        </template>
                      </v-data-table> -->

                      <!-- <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Data da Compra
                              </th>
                              <th class="text-left">
                                Data do Cancelamento
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ item.created | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                              <td class="text-center" v-if="isDeleted(item)">{{ item.deleted | datas('DD/MM/YYYY HH:mm:ss') }}</td>
                              <td class="text-center" v-else>-</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table> -->
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
                
          </div>
      </v-container>
    </v-card>

    <ModalJogos />
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
// import date from '@/views/components/date'

import moment from 'moment'

import validacao from './validacao'

const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalTransmissoes',
  mixins: [validationMixin],
  components: {
    ModalJogos: () => import('./modalJogos'),
    Modal: () => import('@/views/components/modal'),
    // date
  },
  data: () => ({
    loadingModal: false,
    activeModal: false,
    loading: false,
    entityId: null,
    dtFinal: null,
    dtInicial: null,
    lotteryGuesses: null,
    lotteryGames: null,
    data: null,
    revendedor: '',
    tab: 'tab-1',
    headers: [
      { align: 'start', class: 'table-header', text: '', value: 'action' },
      { align: 'start', class: 'table-header', text: 'Nr. Jogo', value: 'gameNumber' },
      { align: 'start', class: 'table-header', text: 'Loteria', value: 'sweepstakes.cod' },
      { align: 'start', class: 'table-header', text: 'Cód. Bilhete', value: 'codId' },
      // { align: 'start', class: 'table-header', text: 'Data de Transmissão', value: 'created' },
      // { align: 'center', class: 'table-header', text: 'Data do Cancelamento', value: 'deleted' },
      { align: 'end', class: 'table-header', text: 'Valor', value: 'value' },
      { sortable: false, align: 'left', text: '', value: 'data-table-expand' },
    ],

    dessertHeaders: [
      { align: 'start', class: 'table-header', text: 'Data de Transmissão', value: 'created' },
      { align: 'center', class: 'table-header', text: 'Data do Cancelamento', value: 'deleted' },
    ],

    headersListCreditosVendidos: [
      { align: 'start', class: 'table-header', text: '', value: 'action' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
      { align: 'center', class: 'table-header', text: 'Dt. Cancelamento', value: 'updated' },
      { align: 'center', class: 'table-header', text: 'Dt. Compra', value: 'created' },
      { align: 'end', class: 'table-header', text: 'Valor', value: 'value' },
    ]
  }),
  
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'transmissoes', 'listTransmissionsNotDeleted', 'listTransmissionsDeleted']),
    ...mapGetters('vendasCreditos', ['listCreditosVendidos', 'listCreditsNotCanceled', 'listCreditsCanceled']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    totalValoresTransmissoes () {
      return this.listTransmissionsNotDeleted.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    },

    totalValoresTransmissoesDeleted () {
      return this.listTransmissionsDeleted.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    },

    totalValoresCreditsCanceled () {
      return this.listCreditsCanceled.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    },

    totalValoresVendasCreditos () {
      return this.listCreditsNotCanceled.reduce((accumulator, currentValue) => {
					return accumulator + parseFloat(currentValue.value)
				}, 0)
    }
  },
  mounted () {
    Events.$on('modal::historicoVendas::transmissoes', async (e) => {
      this.activeModal = true
      this.loadingModal = true

      this.data = e.dtStart
      this.revendedor = e.entity_cod
      this.entityId = e.entityId

      this.formatDate(e)

      await this.getItens({ dtInicial: e.dtStart, dtFinal: e.dtEnd, entityId: e.entityId })

      await this.getVendasCreditos({ dtStart: e.dtStart, dtEnd: e.dtEnd, entityId: e.entityId })

      this.loadingModal = false
    })
  },
  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'getItem', 'getFilterVendas', 'limparItem']),
    ...mapActions('jogos', ['getBilhete', 'deletarItem']),
    ...mapActions('vendasCreditos', {
      getVendasCreditos: 'listaCreditosVendidos',
      cancelarCredito: 'editarItem'
    }),
    openModal () {
      this.activeModal = true
    },

    opemDadosJogos(val) {
      Events.$emit('modal::historicoVendas::jogos', { val, data: this.data, revendedor: this.revendedor })
    },

    closeModal () {
      this.activeModal = false
      this.tab = 'tab-1'
      this.limparItem()
    },

    formatDate (data) {
      if (data.hasMonthOrDate === 2) {
        this.dtInicial = moment(data.data, 'MM/YYYY').startOf('month').format('YYYY-MM-DD')
        this.dtFinal = moment(data.data, 'MM/YYYY').endOf('month').format('YYYY-MM-DD')

        return
      } 

      this.dtInicial = moment(data.data).format('YYYY-MM-DD')
      this.dtFinal = moment(data.data).format('YYYY-MM-DD')
    },

    isDeleted (data) {
      if (moment(data.deleted).format('DD/MM/YYYY') > '01/01/1970'){
        return true
      }

      return false
    },

    isCancelado: (data) => data.status === 'C',

    displayDate (date, type) {
      if (type === 2) return moment(date).format('MM/YYYY')

      return moment(date).format('DD/MM/YYYY')
    },

    cancelarBilhete (item) {
      this.loading = true
      const dados = Object.assign({}, item)
      const bilhete = {
        codBilhete: dados.codId.toUpperCase(),
        entityId: dados.entityId
      }

      this.getBilhete(bilhete).then((result) =>{
        if (moment(result.data.resultMap.deleted).format('DD/MM/YYYY') !== '01/01/1970') {
          this.$swal({
            icon: 'error',
            text: `Bilhete com o código ${bilhete.codBilhete} no valor de ${currency(result.data.resultMap.value)}, já está excluído`,
            showConfirmButton: false,
            // confirmButtonText: 'Sim',
            // cancelButtonText: 'Não',
          })
          .finally(() => this.loading = false)

          return
        }

        this.$swal({
          icon: 'warning',
          text: `Tem certeza que deseja cancelar esse bilhete com o código ${bilhete.codBilhete} no valor de ${currency(result.data.resultMap.value)}?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.value) {
            this.deletarItem(bilhete).then(() => {
              successSnackbar('Bilhete excluído com sucesso!')
              // vm.buscar()
            }).catch(err => {
              if (/soft-delete/.test(err.error)) {
                errorSnackbar(`Bilhete com o código ${bilhete.codBilhete}, já estava excluído!`)
                return
              }
              errorSnackbar(err.error)
            })
            .finally(() => {
              this.loading = false
            })
          } else {
            this.loading = false
          }
        })
      })
      .catch(err => {
        this.loading = false
        errorSnackbar(err.error)
      })
    },

    handleCancelarCredito(item) {
      // const dados = Object.assign({}, item)
      this.loading = true

      const self = this

      if (item.status !== 'N') {
        const nameUserWeb = item.entriesDest ? item.entriesDest.entityDest.jsonData.fullName : ''
         this.$swal({
          icon: 'warning',
          // text: `Tem certeza que deseja cancelar essa venda de crédito, no valor de ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.value)}`,
          text: `Atenção, o valor de ${currency(item.value)} será descontado da conta do cliente: ${nameUserWeb}, certeza que deseja cancelar?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        })
        .then((result) => {
          if (result.value) {
            this.loadingBtnsListagem = true
            this.cancelarCredito({ id: item.id }).then(() => {
              this.loadingPag = true
              this.getVendasCreditos({ dtStart: self.data, dtEnd: self.data, entityId: self.entityId })
                .finally(() => this.loading = false)
              successSnackbar('Crédito cancelado com sucesso!')
            }).catch(err => {
              errorSnackbar(err.error)
            })
            .finally(() => this.loading = false)
          }
        })

        return false
      }


      this.$swal({
        icon: 'warning',
        // text: `Tem certeza que deseja cancelar essa venda de crédito, no valor de ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.value)}`,
        text: `Tem certeza que deseja cancelar essa venda de crédito, no valor de ${currency(item.value)}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
      .then((result) => {
        if (result.value) {
          this.loading = true
          this.cancelarCredito({ id: item.id }).then(() => {
            this.loadingPag = true
            this.getVendasCreditos({ dtStart: self.data, dtEnd: self.data, entityId: self.entityId })
              .finally(() => this.loading = false)
            successSnackbar('Crédito cancelado com sucesso!')
          }).catch(err => {
            errorSnackbar(err)
          })
          .finally(() => this.loading = false)
        }
      })
      .finally(() => this.loading = false)
    },
  },

  beforeDestroy () {
    Events.$off('modal::historicoVendas::transmissoes')
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';

  .box-table::v-deep {
    .cancelado {
      color: #F00;
    }

    &-row {
      cursor: pointer;
    }

    .btn-remove {
      height: auto !important
    }

    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }

    table {
      min-width: 360px;
    }
  }

</style>